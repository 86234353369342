import { Player, PlayIcon } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, Play } from './play';
import { type UseAlbumPlayProps, useAlbumPlay } from './use-album-play';

export type AlbumPlayProps = Omit<UseAlbumPlayProps, 'type' | 'targeting'> &
  OptionalPlayProps;

export function AlbumPlay({
  color = 'white',
  size = 40,
  seed,
  ...props
}: AlbumPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const play = useAlbumPlay(props);

  return isPremium ?
      <Play
        {...props}
        color={color}
        seed={seed}
        size={size}
        type={Playback.StationType.Album}
      />
    : <Player.Play color={color} onClick={play.onClick}>
        <PlayIcon size={size} />
      </Player.Play>;
}
