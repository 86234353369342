import { Playback } from '@iheartradio/web.playback';

import { usePlay } from './use-play';

export type UseFavoritesPlayProps = Omit<
  Playback.FavoritesStation,
  'type' | 'targeting' | 'seed'
>;

export function useFavoritesPlay(props: UseFavoritesPlayProps) {
  const play = usePlay({
    ...props,
    type: Playback.StationType.Favorites,
  });

  return play;
}
