import { Playback } from '@iheartradio/web.playback';

import { type OptionalPlayProps, Play } from './play';
import type { UseLivePlayProps } from './use-live-play';

export type LivePlayProps = UseLivePlayProps & OptionalPlayProps;

export function LivePlay({
  color = 'white',
  size = 40,
  ...rest
}: LivePlayProps) {
  const props = {
    ...rest,
  };

  return (
    <Play
      color={color}
      size={size}
      type={Playback.StationType.Live}
      {...props}
    />
  );
}
