import { Button, Notification } from '@iheartradio/web.companion';
import { Link as RouterLink } from '@remix-run/react';
import type { ReactNode } from 'react';

import { useConfig } from '~app/contexts/config';

export function UpgradeCTANotification(props: {
  dismiss?: () => void;
  children?: ReactNode;
}) {
  const { dismiss, children = 'Want to listen to anything, anytime?' } = props;

  const config = useConfig();

  return (
    <Notification
      data-test="upgrade-cta-notification"
      footer={
        <Button
          as={RouterLink}
          color="gray"
          kind="tertiary"
          size={{ '@xsmall': 'small', '@medium': 'large' }}
          to={`${config.urls.account}/subscribe`}
        >
          Listen with iHeart All Access
        </Button>
      }
      kind="info"
      onDismiss={dismiss}
    >
      {children}
    </Notification>
  );
}
